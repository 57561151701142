/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, Image, jsx } from 'theme-ui';
import styles from './logo.style';

type PropsType = {
	imgSrc?: string;
	imgAlt?: string;
	path?: string;
    shopLogo?: string;
};


const Logo: React.FC<PropsType> = ({ imgSrc, imgAlt, path, shopLogo }) => {

	return (
		<Box className="logo" sx={styles.wrapper}>
			<Link to={path}>
				{shopLogo != null ? <img src={shopLogo} alt="logo" style={{ width: 96, height: 60}}/> : <svg sx={styles.logo} data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1086.5 301.2"><defs></defs><path d="M636 581c0 79-52 110-109 110-44 0-75-12-95-28l23-46c12 11 38 23 60 23 31 0 63-9 63-53v-22a72 72 0 01-63 34c-61 0-98-47-98-106 0-56 34-104 97-104 27 0 50 14 64 32v-27h58zm-109-29c28 0 52-19 52-55 0-38-22-60-54-60s-50 26-50 57c0 32 20 58 52 58zM743 438c7-37 38-49 56-49l15 2v58a122 122 0 00-25-3c-22 0-44 13-44 39v110h-58V394h56zM939 389c59 0 111 43 111 105 0 63-52 105-111 105-58 0-110-42-110-105s52-105 110-105zm0 160c29 0 54-24 54-55a54 54 0 10-107 0c0 32 25 55 53 55zM1187 389c21 0 48 5 82 30l-26 43a80 80 0 00-53-20c-34 0-57 21-57 52s23 52 57 52c10 0 33-2 53-20l26 43c-34 25-61 30-82 30-69 0-112-45-112-105s43-105 112-105zM1439 394h64l-130 292h-59l46-108-82-184h65l48 125z" transform="translate(-417 -389)" /></svg>}
			</Link>
		</Box>
	);
};

export default Logo;
