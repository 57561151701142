import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import { Box, Flex, Text, Button, Container } from "theme-ui";
import Logo from "../components/logo/logo";

const NotFoundPage: React.FC<{}> = () => {
  return (
    <Container>
      <SEO title="404: Not found" />
      <Flex
        sx={{
          paddingTop: "20px",
          paddingBottom: "25px",
          paddingLeft: "5px",
          alignItems: "top",
          justifyContent: "space-between",
        }}
      >
        <Logo path={"/"} />
      </Flex>

      <div>
        <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
            <div className="border-t border-gray-200 text-center pt-8">
              <h1 className="text-9xl font-bold text-green-600">404</h1>
              <h1 className="text-6xl font-medium py-8">
                oops! Page not found
              </h1>
              <p className="text-2xl pb-8 px-12 font-medium">
                Oops! The page you are looking for does not exist. It might have
                been moved or deleted.
              </p>
              <Link
                to="/"
                className="py-4 px-6  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NotFoundPage;
